<template>
  <div>
    <!-- <p class="txt-header">
      {{ $t("attendance") }}
    </p> -->
    <div style="margin-left: 1%;display:flex;">
      <p class="ml-4 headtext txt-header">
        {{ $t("attendance") }}
      </p>
    </div>

    <div style="margin-left: 1.5rem; margin-right: 1.5rem">
      <v-tabs class="mt-3" grey--text background-color="#f0f8fc" slider-color="#F99D20" v-model="activeTab" show-arrows>
        <v-tab class="text-capitalize" style="border-radius: 10px 10px 0px 5px;width: 150px;margin-right: 3px;font-size: 16px;" v-for="tab in tabs" :key="tab.id" :to="tab.route" v-show="tab.showthis" exact>{{
          tab.name == "Organization Holidays"
            ? $t("WorkdayCalendar")
            : tab.name == "Time Entries Setting"
              ? $t("TimeEntriesSetting")
              : tab.name == "Shift Code Master Data"
                ? $t("ShiftCodeMasterData")
                : $t("workinlocation")}}
        </v-tab>
      </v-tabs>
      <v-card>
        <router-view></router-view>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      activeTab: `/organization/attendance/${this.id}`,
      showthis: false,
      tabs: [
        // {
        //   id: 6,
        //   name: "Workin Location",
        //   route: `/organization/attendance/${this.id}`,
        //   showthis: false,
        // },
        {
          id: 1,
          name: "Organization Holidays",
          route: `/organization/attendance/${this.id}/timeworkdaycalendar`,
          showthis: false,
        },
        // {
        //   id: 2,
        //   name: "Time Groups",
        //   route: `/organization/attendance/${this.id}/timegroups`,
        //   showthis: false,
        // },
        // {
        //   id: 3,
        //   name: "Shift Assignment",
        //   route: `/organization/attendance/${this.id}/shiftassignment`,
        //   showthis: false,
        // },
        {
          id: 4,
          name: "Shift Code Master Data",
          route: `/organization/attendance/${this.id}/shiftcode`,
          showthis: false,
        },
        {
          id: 5,
          name: "Time Entries Setting",
          route: `/organization/attendance/${this.id}/timeattendancesetting`,
          showthis: false,
        },
      ],
    };
  },
  mounted() {
    let perid = sessionStorage.getItem("permissionID").split(",");
    let roleID = sessionStorage.getItem("roleID");
    if (roleID == "Admin" || roleID == "HR") {
      for (let j = 0; j < this.tabs.length; j++) {
        this.tabs[j].showthis = true;
      }
    }
    if (perid.some((e) => e == 233)) {
      this.tabs[0].showthis = true;
    }
    if (perid.some((e) => e == 20)) {
      this.tabs[1].showthis = true;
    }
    if (perid.some((e) => e == 30)) {
      this.tabs[2].showthis = true;
    }
    if (perid.some((e) => e == 35)) {
      this.tabs[3].showthis = true;
    }
    if (this.$route.path == "/organization/attendance/workinlocation") {
      for (let j = 0; this.tabs.length; j++) {
        if (this.tabs[j].showthis == true) {
          this.activeTab = this.tabs[j].route;
          this.$router.push(this.tabs[j].route).catch(() => { });
          break;
        }
      }
    }
  },
};
</script>

<style scoped>
.v-tab:before {
  background: none !important;
}

.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f99d20 !important;
  border-radius: 15px 50px 0px 0px;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.54);
  border-radius: 15px 50px 0px 0px;
}

.v-tabs-bar .v-tab {
  background-color: white;
}

.active {
  cursor: pointer !important;
}

.v-tab--active {
  color: #3c4043;
  border-radius: 15px 50px 0px 0px;
  z-index: 1;
}

>>>.v-tabs-slider {
  background-color: currentColor;
  height: 0;
  width: 0;
}
</style>